/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExamStructTaskDTO } from '../models/ExamStructTaskDTO';
import type { ExamStructTaskGetListResponse } from '../models/ExamStructTaskGetListResponse';
import type { ExamStructTaskSaveRequest } from '../models/ExamStructTaskSaveRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ExamStructTaskService {

    /**
     * Получение списка ExamStructTask
     * @returns ExamStructTaskGetListResponse Список ExamStructTask
     * @throws ApiError
     */
    public static getAppApiReferencesExamstructtaskcrudGetlist({
        subjectId,
        idsArray,
        query,
        examTypeIdsArray,
        limit,
        offset,
    }: {
        subjectId: string,
        idsArray?: Array<number>,
        query?: string | null,
        examTypeIdsArray?: Array<number>,
        limit?: number,
        offset?: number,
    }): CancelablePromise<ExamStructTaskGetListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/subjects/{subjectId}/exam-struct-tasks',
            path: {
                'subjectId': subjectId,
            },
            query: {
                'ids[]': idsArray,
                'query': query,
                'examTypeIds[]': examTypeIdsArray,
                'limit': limit,
                'offset': offset,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }

    /**
     * Создание ExamStructTask
     * @returns ExamStructTaskSaveRequest Созданный ExamStructTask
     * @throws ApiError
     */
    public static postAppApiReferencesExamstructtaskcrudActioncreate({
        subjectId,
        requestBody,
    }: {
        subjectId: string,
        /**
         * Данные ExamStructTask
         */
        requestBody?: ExamStructTaskSaveRequest,
    }): CancelablePromise<ExamStructTaskSaveRequest> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/subjects/{subjectId}/exam-struct-tasks',
            path: {
                'subjectId': subjectId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }

    /**
     * Получение ExamStructTask по идентификатору
     * @returns ExamStructTaskDTO Созданный ExamStructTask
     * @throws ApiError
     */
    public static getAppApiReferencesExamstructtaskcrudGetbyid({
        id,
        subjectId,
    }: {
        /**
         * Идентификатор ExamStructTask
         */
        id: number,
        subjectId: string,
    }): CancelablePromise<ExamStructTaskDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/subjects/{subjectId}/exam-struct-tasks/{id}',
            path: {
                'id': id,
                'subjectId': subjectId,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `ExamStructTask с таким идентификатором не найден`,
            },
        });
    }

    /**
     * Обновление ExamStructTask
     * Для обновления не обязательно указывать все поля, достаточно указать только те, которые нужно обновить
     * @returns void
     * @throws ApiError
     */
    public static putAppApiReferencesExamstructtaskcrudActionupdate({
        id,
        subjectId,
        requestBody,
    }: {
        /**
         * Идентификатор ExamStructTask
         */
        id: number,
        subjectId: string,
        /**
         * ExamStructTask
         */
        requestBody?: ExamStructTaskSaveRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/subjects/{subjectId}/exam-struct-tasks/{id}',
            path: {
                'id': id,
                'subjectId': subjectId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `ExamStructTask с таким идентификатором не найден`,
            },
        });
    }

    /**
     * Удаление ExamStructTask
     * @returns void
     * @throws ApiError
     */
    public static deleteAppApiReferencesExamstructtaskcrudActiondelete({
        id,
        subjectId,
    }: {
        /**
         * Идентификатор ExamStructTask
         */
        id: number,
        subjectId: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/subjects/{subjectId}/exam-struct-tasks/{id}',
            path: {
                'id': id,
                'subjectId': subjectId,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `ExamStructTask с таким идентификатором не найден`,
            },
        });
    }

    /**
     * Обновление ExamStructTask
     * Для обновления не обязательно указывать все поля, достаточно указать только те, которые нужно обновить
     * @returns void
     * @throws ApiError
     */
    public static patchAppApiReferencesExamstructtaskcrudActionupdate({
        id,
        subjectId,
        requestBody,
    }: {
        /**
         * Идентификатор ExamStructTask
         */
        id: number,
        subjectId: string,
        /**
         * ExamStructTask
         */
        requestBody?: ExamStructTaskSaveRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/subjects/{subjectId}/exam-struct-tasks/{id}',
            path: {
                'id': id,
                'subjectId': subjectId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `ExamStructTask с таким идентификатором не найден`,
            },
        });
    }

}
