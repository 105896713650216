import {FC, memo} from "react";
import {Avatar, SvgIcon} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import {INavItemProps} from "./types";
import styles from './styles.module.css'
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";

/** Кнопка навигации */
const NavItem: FC<INavItemProps> = (props) => {
    const {Icon, onClick, selected, text, href, withAvatar, avatarUrl, IconElement} = props

    const Element = (
        <>
            {(withAvatar || Icon || IconElement) && (
                <IconButton color={'inherit'}>
                    {withAvatar && <Avatar sx={{width: '24px', height: '24px'}} src={avatarUrl || undefined} />}

                    {IconElement && <SvgIcon component={IconElement} inheritViewBox />}

                    {Icon}
                </IconButton>
            )}

            {text && (
                <Typography
                    padding={'0 0 0 4px'}
                    color={'inherit'}
                    fontSize={'14px'}
                    fontWeight={500}
                    lineHeight={'20px'}
                    letterSpacing={'0.14px'}
                >
                    {text}
                </Typography>
            )}
        </>
    )

    if (!href) {
        return (
            <div className={styles.NavItem__block} data-active={selected || undefined} onClick={onClick}>
                {Element}
            </div>
        )
    }

    return (
        <div className={styles.NavItem__block} data-active={selected || undefined}>
            <Link to={href}>
                {Element}
            </Link>
        </div>
    )
}

export default memo(NavItem)
