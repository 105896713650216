import getToken from "./getToken";
// @ts-ignore
import Cookies from "js-cookie";
import {setCookie} from "./setCookie";

export const logout = () => {
    const token = getToken()

    if (token) {
        // удаляем куку
        setCookie('uchru_token', token, {expires: -1})

        window.location.reload()
    }
}