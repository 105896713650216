/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ProgramItemTypeEnum {
    LESSON = 'lesson',
    WEBINAR = 'webinar',
    TEST = 'test',
}
