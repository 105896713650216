/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProgramStudentDTO } from '../models/ProgramStudentDTO';
import type { SaveStudentProgramsRequest } from '../models/SaveStudentProgramsRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProgramStudentsService {

    /**
     * Получение программ ученика
     * @returns ProgramStudentDTO Список
     * @throws ApiError
     */
    public static getAppApiCalendarProgramstudentcrudGetbystudentlist({
        studentId,
    }: {
        studentId: string,
    }): CancelablePromise<Array<ProgramStudentDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/program-students/students/{studentId}/programs',
            path: {
                'studentId': studentId,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }

    /**
     * Изменение программ ученика
     * @returns void
     * @throws ApiError
     */
    public static putAppApiCalendarProgramstudentcrudSavebystudentlist({
        studentId,
        requestBody,
    }: {
        studentId: string,
        /**
         * Данные
         */
        requestBody?: SaveStudentProgramsRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/program-students/students/{studentId}/programs',
            path: {
                'studentId': studentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }

}
