/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SetTaskCheckingResultRequest } from '../models/SetTaskCheckingResultRequest';
import type { TaskCheckingResultStatusDTO } from '../models/TaskCheckingResultStatusDTO';
import type { TaskResultForCheckingResponse } from '../models/TaskResultForCheckingResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TaskCheckingService {

    /**
     * Получение списка задач для проверки.
     * @returns TaskResultForCheckingResponse Список задач для проверки
     * @throws ApiError
     */
    public static getAppApiEducationtaskTaskcheckingGetlistforchecking({
        subjectIdsArray,
        examTypeIdsArray,
        studentIdsArray,
        curatorIdsArray,
        examStructTaskIdsArray,
        isForBank,
        isChecked,
        limit,
        offset,
    }: {
        subjectIdsArray?: Array<number>,
        examTypeIdsArray?: Array<number>,
        studentIdsArray?: Array<number>,
        curatorIdsArray?: Array<number>,
        examStructTaskIdsArray?: Array<number>,
        isForBank?: boolean | null,
        isChecked?: boolean | null,
        limit?: number,
        offset?: number,
    }): CancelablePromise<TaskResultForCheckingResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/tasks-checking/task-results',
            query: {
                'subjectIds[]': subjectIdsArray,
                'examTypeIds[]': examTypeIdsArray,
                'studentIds[]': studentIdsArray,
                'curatorIds[]': curatorIdsArray,
                'examStructTaskIds[]': examStructTaskIdsArray,
                'isForBank': isForBank,
                'isChecked': isChecked,
                'limit': limit,
                'offset': offset,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Задача с таким идентификатором не найдена`,
            },
        });
    }

    /**
     * Получение задачи по идентификатору.
     * @returns TaskCheckingResultStatusDTO Задача
     * @throws ApiError
     */
    public static getAppApiEducationtaskTaskcheckingGetbyid({
        id,
    }: {
        /**
         * Идентификатор задачи
         */
        id: number,
    }): CancelablePromise<TaskCheckingResultStatusDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/tasks-checking/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Задача с таким идентификатором не найдена`,
            },
        });
    }

    /**
     * Установить результат проверки задачи
     * @returns void
     * @throws ApiError
     */
    public static postAppApiEducationtaskTaskcheckingCheck({
        id,
        requestBody,
    }: {
        id: string,
        /**
         * Результат
         */
        requestBody?: SetTaskCheckingResultRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/task-results/{id}/check',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }

}
