/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TextTaskExpectedValueTypeEnum {
    NUMBER = 'number',
    SEQUENCE_OF_NUMBERS = 'sequence_of_numbers',
    FREE_ENTRY = 'free_entry',
}
