/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LessonSubTopicDTO } from '../models/LessonSubTopicDTO';
import type { LessonSubTopicGetListResponse } from '../models/LessonSubTopicGetListResponse';
import type { LessonSubTopicOrderPositionSaveRequest } from '../models/LessonSubTopicOrderPositionSaveRequest';
import type { LessonSubTopicSaveRequest } from '../models/LessonSubTopicSaveRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LessonSubTopicService {

    /**
     * Получение списка глав.
     * @returns LessonSubTopicGetListResponse Список LessonSubTopic
     * @throws ApiError
     */
    public static getAppApiEducationLessonsubtopiccrudGetlist({
        lessonId,
        query,
    }: {
        /**
         * Идентификатор урока
         */
        lessonId: number,
        query?: string | null,
    }): CancelablePromise<LessonSubTopicGetListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/lessons/{lessonId}/sub-topics',
            path: {
                'lessonId': lessonId,
            },
            query: {
                'query': query,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }

    /**
     * Создание главы.
     * @returns LessonSubTopicSaveRequest Созданный LessonSubTopic
     * @throws ApiError
     */
    public static postAppApiEducationLessonsubtopiccrudActioncreate({
        lessonId,
        requestBody,
    }: {
        /**
         * Идентификатор урока
         */
        lessonId: number,
        /**
         * Данные LessonSubTopic
         */
        requestBody?: LessonSubTopicSaveRequest,
    }): CancelablePromise<LessonSubTopicSaveRequest> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/lessons/{lessonId}/sub-topics',
            path: {
                'lessonId': lessonId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }

    /**
     * Получение главы по идентификатору.
     * @returns LessonSubTopicDTO Созданный LessonSubTopic
     * @throws ApiError
     */
    public static getAppApiEducationLessonsubtopiccrudGetbyid({
        id,
        lessonId,
    }: {
        /**
         * Идентификатор LessonSubTopic
         */
        id: number,
        /**
         * Идентификатор урока
         */
        lessonId: number,
    }): CancelablePromise<LessonSubTopicDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/lessons/{lessonId}/sub-topics/{id}',
            path: {
                'id': id,
                'lessonId': lessonId,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `LessonSubTopic с таким идентификатором не найден`,
            },
        });
    }

    /**
     * Удаление главы.
     * @returns void
     * @throws ApiError
     */
    public static deleteAppApiEducationLessonsubtopiccrudActiondelete({
        id,
        lessonId,
    }: {
        /**
         * Идентификатор LessonSubTopic
         */
        id: number,
        /**
         * Идентификатор урока
         */
        lessonId: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/lessons/{lessonId}/sub-topics/{id}',
            path: {
                'id': id,
                'lessonId': lessonId,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `LessonSubTopic с таким идентификатором не найден`,
            },
        });
    }

    /**
     * Обновление главы.
     * Для обновления не обязательно указывать все поля, достаточно указать только те, которые нужно обновить
     * @returns void
     * @throws ApiError
     */
    public static patchAppApiEducationLessonsubtopiccrudActionupdate({
        id,
        lessonId,
        requestBody,
    }: {
        /**
         * Идентификатор LessonSubTopic
         */
        id: number,
        /**
         * Идентификатор урока
         */
        lessonId: number,
        /**
         * LessonSubTopic
         */
        requestBody?: LessonSubTopicSaveRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/lessons/{lessonId}/sub-topics/{id}',
            path: {
                'id': id,
                'lessonId': lessonId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `LessonSubTopic с таким идентификатором не найден`,
            },
        });
    }

    /**
     * Установка видимости главы
     * @returns void
     * @throws ApiError
     */
    public static postAppApiEducationLessonsubtopiccrudActionsetvisible({
        id,
        lessonId,
        value,
    }: {
        /**
         * Идентификатор LessonSubTopic
         */
        id: number,
        /**
         * Идентификатор урока
         */
        lessonId: number,
        value: 'hide' | 'show',
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/lessons/{lessonId}/sub-topics/{id}/{value}',
            path: {
                'id': id,
                'lessonId': lessonId,
                'value': value,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `LessonSubTopic с таким идентификатором не найден`,
            },
        });
    }

    /**
     * Установка порядкового номера
     * @returns void
     * @throws ApiError
     */
    public static postAppApiEducationLessonsubtopiccrudActionsetorderposition({
        id,
        lessonId,
        requestBody,
    }: {
        /**
         * Идентификатор LessonSubTopic
         */
        id: number,
        /**
         * Идентификатор урока
         */
        lessonId: number,
        /**
         * Данные для изменения порядкового номера
         */
        requestBody?: LessonSubTopicOrderPositionSaveRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/lessons/{lessonId}/sub-topics/{id}/order-position',
            path: {
                'id': id,
                'lessonId': lessonId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `LessonSubTopic с таким идентификатором не найден`,
            },
        });
    }

}
