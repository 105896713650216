/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DayInScheduleTypeEnum {
    WORKDAY = 'workday',
    DAY_OFF = 'day_off',
    DUTY = 'duty',
}
