/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LessonDTO } from '../models/LessonDTO';
import type { LessonGetListResponse } from '../models/LessonGetListResponse';
import type { LessonSaveRequest } from '../models/LessonSaveRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LessonService {

    /**
     * Получение списка Lesson.
     * @returns LessonGetListResponse Список Lesson
     * @throws ApiError
     */
    public static getAppApiEducationLessoncrudGetlist({
        idsArray,
        limit,
        offset,
        query,
        subjectIdsArray,
        topicIdsArray,
        examStructTaskIdsArray,
        isPublished,
        isExcludeArchive,
        examTypeIdsArray,
        isDemo,
    }: {
        idsArray?: Array<number>,
        limit?: number,
        offset?: number,
        query?: string | null,
        subjectIdsArray?: Array<number>,
        topicIdsArray?: Array<number> | null,
        examStructTaskIdsArray?: Array<number> | null,
        isPublished?: boolean | null,
        isExcludeArchive?: boolean | null,
        examTypeIdsArray?: Array<number> | null,
        isDemo?: boolean | null,
    }): CancelablePromise<LessonGetListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/lessons',
            query: {
                'ids[]': idsArray,
                'limit': limit,
                'offset': offset,
                'query': query,
                'subjectIds[]': subjectIdsArray,
                'topicIds[]': topicIdsArray,
                'examStructTaskIds[]': examStructTaskIdsArray,
                'isPublished': isPublished,
                'isExcludeArchive': isExcludeArchive,
                'examTypeIds[]': examTypeIdsArray,
                'isDemo': isDemo,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }

    /**
     * Создание Lesson.
     * @returns LessonSaveRequest Созданный Lesson
     * @throws ApiError
     */
    public static postAppApiEducationLessoncrudActioncreate({
        requestBody,
    }: {
        /**
         * Данные Lesson
         */
        requestBody?: LessonSaveRequest,
    }): CancelablePromise<LessonSaveRequest> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/lessons',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }

    /**
     * Получение Lesson по идентификатору.
     * @returns LessonDTO Lesson
     * @throws ApiError
     */
    public static getAppApiEducationLessoncrudGetbyid({
        id,
    }: {
        /**
         * Идентификатор Lesson
         */
        id: number,
    }): CancelablePromise<LessonDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/lessons/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Lesson с таким идентификатором не найден`,
            },
        });
    }

    /**
     * Удаление Lesson.
     * @returns void
     * @throws ApiError
     */
    public static deleteAppApiEducationLessoncrudActiondelete({
        id,
    }: {
        /**
         * Идентификатор Lesson
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/lessons/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Lesson с таким идентификатором не найден`,
            },
        });
    }

    /**
     * Обновление Lesson.
     * Для обновления не обязательно указывать все поля, достаточно указать только те, которые нужно обновить
     * @returns void
     * @throws ApiError
     */
    public static patchAppApiEducationLessoncrudActionupdate({
        id,
        requestBody,
    }: {
        /**
         * Идентификатор Lesson
         */
        id: number,
        /**
         * Lesson
         */
        requestBody?: LessonSaveRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/lessons/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Lesson с таким идентификатором не найден`,
            },
        });
    }

    /**
     * Отправка в архив
     * @returns void
     * @throws ApiError
     */
    public static postAppApiEducationLessoncrudActionarchive({
        id,
    }: {
        /**
         * Идентификатор Lesson
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/lessons/{id}/archive',
            path: {
                'id': id,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Lesson с таким идентификатором не найден`,
            },
        });
    }

    /**
     * Публикация
     * @returns void
     * @throws ApiError
     */
    public static postAppApiEducationLessoncrudActionpublish({
        id,
    }: {
        /**
         * Идентификатор Lesson
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/lessons/{id}/publish',
            path: {
                'id': id,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Lesson с таким идентификатором не найден`,
            },
        });
    }

}
