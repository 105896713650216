// @ts-ignore
import Cookies from "js-cookie";

export const setCookie = (
    name: string,
    value: string,
    options: Record<string, any>,
): void => {
    if (options.expires && options.expires === -1) {
        Cookies.remove(name)
    } else {
        Cookies.set(name, value, { path: '/', ...options})
    }
}