/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmployeeDTO } from '../models/EmployeeDTO';
import type { RegisterStudentRequest } from '../models/RegisterStudentRequest';
import type { StudentDTO } from '../models/StudentDTO';
import type { StudentForSelectorDTO } from '../models/StudentForSelectorDTO';
import type { StudentGetListResponse } from '../models/StudentGetListResponse';
import type { StudentSaveRequest } from '../models/StudentSaveRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StudentService {

    /**
     * Получение списка учеников.
     * @returns StudentGetListResponse Список Employee
     * @throws ApiError
     */
    public static getAppApiUserStudentcrudGetlist({
        limit,
        offset,
        query,
        parentPhoneNumber,
    }: {
        limit?: number,
        offset?: number,
        query?: string | null,
        parentPhoneNumber?: string | null,
    }): CancelablePromise<StudentGetListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/students',
            query: {
                'limit': limit,
                'offset': offset,
                'query': query,
                'parentPhoneNumber': parentPhoneNumber,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }

    /**
     * @returns StudentDTO Добавленный пользователь
     * @throws ApiError
     */
    public static postAppApiUserStudentcrudActionregister({
        requestBody,
    }: {
        /**
         * Информация для импорта
         */
        requestBody?: RegisterStudentRequest,
    }): CancelablePromise<StudentDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/students',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
            },
        });
    }

    /**
     * Получение ученика по идентификатору.
     * @returns EmployeeDTO Созданный Employee
     * @throws ApiError
     */
    public static getAppApiUserStudentcrudGetbyid({
        id,
    }: {
        /**
         * Идентификатор Employee
         */
        id: number,
    }): CancelablePromise<EmployeeDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/students/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Ученик с таким идентификатором не найден`,
            },
        });
    }

    /**
     * Обновление ученика.
     * Для обновления не обязательно указывать все поля, достаточно указать только те, которые нужно обновить
     * @returns void
     * @throws ApiError
     */
    public static patchAppApiUserStudentcrudActionupdate({
        id,
        requestBody,
    }: {
        /**
         * Идентификатор ученика
         */
        id: number,
        /**
         * Employee
         */
        requestBody?: StudentSaveRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/students/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Ученик с таким идентификатором не найден`,
            },
        });
    }

    /**
     * Изменение статуса активности.
     * @returns void
     * @throws ApiError
     */
    public static postAppApiUserStudentcrudActionsetactivationstatus({
        id,
        action,
    }: {
        /**
         * Идентификатор ученика
         */
        id: number,
        action: 'activate' | 'deactivate',
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/students/{id}/{action}',
            path: {
                'id': id,
                'action': action,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Ученик с таким идентификатором не найден`,
            },
        });
    }

    /**
     * Получение списка учеников для выбора.
     * @returns StudentForSelectorDTO Список Students
     * @throws ApiError
     */
    public static getAppApiUserStudentcrudGetlistforselect({
        idsArray,
        query,
        subjectId,
    }: {
        idsArray?: Array<number>,
        query?: string | null,
        subjectId?: string | null,
    }): CancelablePromise<Array<StudentForSelectorDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/students/for-select',
            query: {
                'ids[]': idsArray,
                'query': query,
                'subjectId': subjectId,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }

    /**
     * Получение списка всех юзеров для выбора.
     * @returns StudentForSelectorDTO Список Students
     * @throws ApiError
     */
    public static getAppApiUserStudentcrudGetuserslistforselect({
        idsArray,
        query,
        subjectId,
    }: {
        idsArray?: Array<number>,
        query?: string | null,
        subjectId?: string | null,
    }): CancelablePromise<Array<StudentForSelectorDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/students/for-select/users',
            query: {
                'ids[]': idsArray,
                'query': query,
                'subjectId': subjectId,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }

}
