/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum RightsEnum {
    SUPERADMIN = 'superadmin',
    ADMIN = 'admin',
    EMPLOYEE = 'employee',
    PROGRAMS = 'programs',
    STUDENTS = 'students',
    LESSONS = 'lessons',
    TASKS = 'tasks',
    TESTS = 'tests',
    WEBINARS = 'webinars',
    TEST_CHECKING = 'test_checking',
    PROGRAMS_PAYMENT = 'programs_payment',
}
