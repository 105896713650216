/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TaskSourceDTO } from '../models/TaskSourceDTO';
import type { TaskSourceGetListResponse } from '../models/TaskSourceGetListResponse';
import type { TaskSourceSaveRequest } from '../models/TaskSourceSaveRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TaskSourceService {

    /**
     * Получение списка источников.
     * @returns TaskSourceGetListResponse Список TaskSource
     * @throws ApiError
     */
    public static getAppApiEducationtaskTasksourcecrudGetlist({
        idsArray,
        query,
        limit,
        offset,
    }: {
        idsArray?: Array<number>,
        query?: string | null,
        limit?: number,
        offset?: number,
    }): CancelablePromise<TaskSourceGetListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/task-sources',
            query: {
                'ids[]': idsArray,
                'query': query,
                'limit': limit,
                'offset': offset,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }

    /**
     * Создание TaskSource.
     * @returns TaskSourceSaveRequest Созданный TaskSource
     * @throws ApiError
     */
    public static postAppApiEducationtaskTasksourcecrudActioncreate({
        requestBody,
    }: {
        /**
         * Данные TaskSource
         */
        requestBody?: TaskSourceSaveRequest,
    }): CancelablePromise<TaskSourceSaveRequest> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/task-sources',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }

    /**
     * Получение TaskSource по идентификатору.
     * @returns TaskSourceDTO Созданный TaskSource
     * @throws ApiError
     */
    public static getAppApiEducationtaskTasksourcecrudGetbyid({
        id,
    }: {
        /**
         * Идентификатор TaskSource
         */
        id: number,
    }): CancelablePromise<TaskSourceDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/task-sources/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `TaskSource с таким идентификатором не найден`,
            },
        });
    }

    /**
     * Удаление источника задач.
     * @returns void
     * @throws ApiError
     */
    public static deleteAppApiEducationtaskTasksourcecrudActiondelete({
        id,
    }: {
        /**
         * Идентификатор источника задач
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/task-sources/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Источник задач с таким идентификатором не найден`,
            },
        });
    }

    /**
     * Обновление источника задач.
     * Для обновления не обязательно указывать все поля, достаточно указать только те, которые нужно обновить
     * @returns void
     * @throws ApiError
     */
    public static patchAppApiEducationtaskTasksourcecrudActionupdate({
        id,
        requestBody,
    }: {
        /**
         * Идентификатор TaskSource
         */
        id: number,
        /**
         * TaskSource
         */
        requestBody?: TaskSourceSaveRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/task-sources/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `TaskSource с таким идентификатором не найден`,
            },
        });
    }

}
