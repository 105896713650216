/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TopicDTO } from '../models/TopicDTO';
import type { TopicGetListResponse } from '../models/TopicGetListResponse';
import type { TopicSaveRequest } from '../models/TopicSaveRequest';
import type { TopicWithChildrenDTO } from '../models/TopicWithChildrenDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TopicService {

    /**
     * Получение списка Topic
     * @returns TopicGetListResponse Список Topic
     * @throws ApiError
     */
    public static getAppApiReferencesTopiccrudGetlist({
        subjectId,
        idsArray,
        query,
        examTypeIdsArray,
        limit,
        offset,
    }: {
        subjectId: string,
        idsArray?: Array<number> | null,
        query?: string | null,
        examTypeIdsArray?: Array<number>,
        limit?: number,
        offset?: number,
    }): CancelablePromise<TopicGetListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/subjects/{subjectId}/topics',
            path: {
                'subjectId': subjectId,
            },
            query: {
                'ids[]': idsArray,
                'query': query,
                'examTypeIds[]': examTypeIdsArray,
                'limit': limit,
                'offset': offset,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }

    /**
     * Создание Topic
     * @returns TopicWithChildrenDTO Созданный Topic
     * @throws ApiError
     */
    public static postAppApiReferencesTopiccrudActioncreate({
        subjectId,
        requestBody,
    }: {
        subjectId: string,
        /**
         * Данные Topic
         */
        requestBody?: TopicSaveRequest,
    }): CancelablePromise<TopicWithChildrenDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/subjects/{subjectId}/topics',
            path: {
                'subjectId': subjectId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }

    /**
     * Получение списка Topic
     * @returns TopicDTO Список Topic
     * @throws ApiError
     */
    public static getAppApiReferencesTopiccrudGettreelist({
        subjectId,
        parentId,
        idsArray,
        query,
        examTypeIdsArray,
        limit,
        offset,
    }: {
        subjectId: string,
        parentId: string,
        idsArray?: Array<number> | null,
        query?: string | null,
        examTypeIdsArray?: Array<number>,
        limit?: number,
        offset?: number,
    }): CancelablePromise<Array<TopicDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/subjects/{subjectId}/topics/{parentId}/children',
            path: {
                'subjectId': subjectId,
                'parentId': parentId,
            },
            query: {
                'ids[]': idsArray,
                'query': query,
                'examTypeIds[]': examTypeIdsArray,
                'limit': limit,
                'offset': offset,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }

    /**
     * Получение Topic по идентификатору
     * @returns TopicDTO Созданный Topic
     * @throws ApiError
     */
    public static getAppApiReferencesTopiccrudGetbyid({
        id,
        subjectId,
    }: {
        /**
         * Идентификатор Topic
         */
        id: number,
        subjectId: string,
    }): CancelablePromise<TopicDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/subjects/{subjectId}/topics/{id}',
            path: {
                'id': id,
                'subjectId': subjectId,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Topic с таким идентификатором не найден`,
            },
        });
    }

    /**
     * Удаление Topic
     * @returns void
     * @throws ApiError
     */
    public static deleteAppApiReferencesTopiccrudActiondelete({
        id,
        subjectId,
    }: {
        /**
         * Идентификатор Topic
         */
        id: number,
        subjectId: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/subjects/{subjectId}/topics/{id}',
            path: {
                'id': id,
                'subjectId': subjectId,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Topic с таким идентификатором не найден`,
            },
        });
    }

    /**
     * Обновление Topic
     * Для обновления не обязательно указывать все поля, достаточно указать только те, которые нужно обновить
     * @returns void
     * @throws ApiError
     */
    public static patchAppApiReferencesTopiccrudActionupdate({
        id,
        subjectId,
        requestBody,
    }: {
        /**
         * Идентификатор Topic
         */
        id: number,
        subjectId: string,
        /**
         * Topic
         */
        requestBody?: TopicSaveRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/subjects/{subjectId}/topics/{id}',
            path: {
                'id': id,
                'subjectId': subjectId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Topic с таким идентификатором не найден`,
            },
        });
    }

}
