/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmployeeDTO } from '../models/EmployeeDTO';
import type { EmployeeForSelectorDTO } from '../models/EmployeeForSelectorDTO';
import type { EmployeeGetListResponse } from '../models/EmployeeGetListResponse';
import type { EmployeeSaveRequest } from '../models/EmployeeSaveRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EmployeeService {

    /**
     * Получение списка Employee.
     * @returns EmployeeGetListResponse Список Employee
     * @throws ApiError
     */
    public static getAppApiUserEmployeecrudGetlist({
        query,
        limit,
        offset,
        roleIdsArray,
    }: {
        query?: string | null,
        limit?: number,
        offset?: number,
        roleIdsArray?: Array<number>,
    }): CancelablePromise<EmployeeGetListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/employees',
            query: {
                'query': query,
                'limit': limit,
                'offset': offset,
                'roleIds[]': roleIdsArray,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }

    /**
     * Получение списка сотрудников для выбора.
     * @returns EmployeeForSelectorDTO Список Employee
     * @throws ApiError
     */
    public static getAppApiUserEmployeecrudGetlistforselect({
        idsArray,
        query,
        subjectId,
        roleIdsArray,
    }: {
        idsArray?: Array<number>,
        query?: string | null,
        subjectId?: string | null,
        roleIdsArray?: Array<number>,
    }): CancelablePromise<Array<EmployeeForSelectorDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/employees/for-select',
            query: {
                'ids[]': idsArray,
                'query': query,
                'subjectId': subjectId,
                'roleIds[]': roleIdsArray,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }

    /**
     * Получение Employee по идентификатору.
     * @returns EmployeeDTO Созданный Employee
     * @throws ApiError
     */
    public static getAppApiUserEmployeecrudGetbyid({
        id,
    }: {
        /**
         * Идентификатор Employee
         */
        id: number,
    }): CancelablePromise<EmployeeDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/employees/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Employee с таким идентификатором не найден`,
            },
        });
    }

    /**
     * Обновление Employee.
     * Для обновления не обязательно указывать все поля, достаточно указать только те, которые нужно обновить
     * @returns void
     * @throws ApiError
     */
    public static patchAppApiUserEmployeecrudActionupdate({
        id,
        requestBody,
    }: {
        /**
         * Идентификатор Employee
         */
        id: number,
        /**
         * Employee
         */
        requestBody?: EmployeeSaveRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/employees/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Employee с таким идентификатором не найден`,
            },
        });
    }

    /**
     * Изменение статуса активности.
     * @returns void
     * @throws ApiError
     */
    public static postAppApiUserEmployeecrudActionsetactivationstatus({
        id,
        action,
    }: {
        /**
         * Идентификатор Employee
         */
        id: number,
        action: 'activate' | 'deactivate',
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/employees/{id}/{action}',
            path: {
                'id': id,
                'action': action,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Employee с таким идентификатором не найден`,
            },
        });
    }

}
