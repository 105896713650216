/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EducationTestTypeEnum } from '../models/EducationTestTypeEnum';
import type { TestCheckingResultDTO } from '../models/TestCheckingResultDTO';
import type { TestResultCheckedRequest } from '../models/TestResultCheckedRequest';
import type { TestResultGetListResponse } from '../models/TestResultGetListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TestCheckingService {

    /**
     * Получение списка готовых к проверке TestResult
     * @returns TestResultGetListResponse Список TestResult
     * @throws ApiError
     */
    public static getAppApiEducationTestcheckingGetlist({
        limit,
        offset,
        query,
        subjectIdsArray,
        examTypeIdsArray,
        type,
        onlyNotChecked,
        onlyWithManualTasks,
        userId,
        curatorId,
    }: {
        limit?: number,
        offset?: number,
        query?: string | null,
        subjectIdsArray?: Array<number>,
        examTypeIdsArray?: Array<number> | null,
        type?: EducationTestTypeEnum | null,
        onlyNotChecked?: boolean | null,
        onlyWithManualTasks?: boolean | null,
        userId?: number | null,
        curatorId?: number | null,
    }): CancelablePromise<TestResultGetListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/test-checking-results',
            query: {
                'limit': limit,
                'offset': offset,
                'query': query,
                'subjectIds[]': subjectIdsArray,
                'examTypeIds[]': examTypeIdsArray,
                'type': type,
                'onlyNotChecked': onlyNotChecked,
                'onlyWithManualTasks': onlyWithManualTasks,
                'userId': userId,
                'curatorId': curatorId,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }

    /**
     * Получение готового к проверке TestResult по идентификатору.
     * @returns TestCheckingResultDTO Созданный TestResult
     * @throws ApiError
     */
    public static getAppApiEducationTestcheckingGetbyid({
        id,
    }: {
        /**
         * Идентификатор TestResult
         */
        id: number,
    }): CancelablePromise<TestCheckingResultDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/test-checking-results/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `TestResult с таким идентификатором не найден`,
            },
        });
    }

    /**
     * Обновление готового к проверке TestResult.
     * Для обновления не обязательно указывать все поля, достаточно указать только те, которые нужно обновить
     * @returns void
     * @throws ApiError
     */
    public static patchAppApiEducationTestcheckingActionupdate({
        id,
        requestBody,
    }: {
        /**
         * Идентификатор TestResult
         */
        id: number,
        /**
         * TestResult
         */
        requestBody?: TestResultCheckedRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/test-checking-results/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Результат теста с таким идентификатором не найден`,
            },
        });
    }

    /**
     * Сброс теста
     * Сбрасывает результат тестирования. его можно пройти снова, также обновляет все задания в тесте на актуальные
     * @returns void
     * @throws ApiError
     */
    public static postAppApiEducationTestcheckingActionresettestresult({
        id,
    }: {
        /**
         * Идентификатор TestResult
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/test-checking-results/{id}/reset',
            path: {
                'id': id,
            },
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Результат теста с таким идентификатором не найден`,
            },
        });
    }

    /**
     * Получение непроверенного TestResult
     * @returns TestCheckingResultDTO Непроверенный TestResult
     * @throws ApiError
     */
    public static getAppApiEducationTestcheckingGetuncheckedtestresult({
        subjectIdsArray,
    }: {
        subjectIdsArray?: Array<number>,
    }): CancelablePromise<TestCheckingResultDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/test-checking-results/unchecked',
            query: {
                'subjectIds[]': subjectIdsArray,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }

}
