/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ProgramLevelEnum {
    MEDIUM = 'medium',
    ADVANCED = 'advanced',
    HARD = 'hard',
}
